import React, { useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import {
  addCustomer,
} from '../../../../utils/api/customer-api-utils';

const AddCustomerForm = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const onFinish = async (values) => {
    try {
      const addCustomerData = {
        name: values.customer_name,
        email: values.email,
        phone: values.phone,
        gender: values.gender,
        code: values.code,
        balance: values.balance,
      };
      const userDataAddResponse = await addCustomer(addCustomerData);
      //console.log(userDataAddResponse);
      if (userDataAddResponse.hasError) {
        console.log('Cant Edit Customer -> ', userDataAddResponse.errorMessage);
        message.error(userDataAddResponse.errorMessage, 2);
      }
      else {
        console.log('res -> ', userDataAddResponse);
        message.success(userDataAddResponse.message, 1);
        addCustomerData.customer_id = userDataAddResponse.insert_id;
        props.addCustomerInCart(addCustomerData);
        form.resetFields();
        props.setOpen(false)

      }
    } catch (err) {
      message.err('Unable to update user', 3);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const onPhoneChange = (e) => {
    let phoneNumber = e.target.value;
    const re = /^[0-9\b]+$/;
    console.log(e.target.value);
    if (!e.target.value === '' || !re.test(e.target.value)) {  //if contains alphabets in string
      form.setFieldsValue({
        phone: phoneNumber.replace(/[^\d.-]/g, '')
      });
    }

  }

  return (
    <div className='modal__content'>
      <div className='page__form'>
        <Form
          form={form}
          name='basic'
          layout='vertical'
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className='form__row'>
            <div className='form__col'>
              <Form.Item
                label={<p style={{ fontWeight: 'normal', marginBottom: '-2px' }}>Customer Name</p>}
                name='customer_name'
                rules={[
                  {
                    required: true,
                    message: 'Please input customer name!'
                  }
                ]} >
                <Input />
              </Form.Item>
            </div>
            <div className='form__col'>
              <Form.Item
                label={<p style={{ fontWeight: 'normal', marginBottom: '-2px' }}>Phone Number</p>}
                name='phone'
                rules={[
                  {
                    required: true,
                    message: 'Please input valid phone!'
                  }
                ]}>
                <Input onChange={onPhoneChange} />
              </Form.Item>
            </div>
          </div>
          <div className='form__row'>
            <div className='form__col'>
              <Form.Item
                label={<p style={{ fontWeight: 'normal', marginBottom: '-2px' }}>Email Address</p>}
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Please input valid email!',
                    type: "email",
                  }
                ]}>
                <Input />
              </Form.Item>
            </div>
            <div className='form__col'>
              <Form.Item
                label={<p style={{ fontWeight: 'normal', marginBottom: '-2px' }}>Gender</p>}
                name='gender'
                rules={[
                  {
                    required: true,
                    message: 'Please input valid phone!'
                  }
                ]}>
                <Select onChange={handleChange}>
                  <Option value='male'>Male</Option>
                  <Option value='female'>Female</Option>
                  <Option value='other'>Other</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className='form__row'>
            {/* HIDE 'OPENING BALANCE' in edit mode */}
            <div className='form__col'>
              <Form.Item
                label={<p style={{ fontWeight: 'normal', marginBottom: '-2px' }}>Opening Balance</p>}
                name='balance'
                rules={[
                  {
                    required: false,
                    message: 'Please input Balance'
                  }
                ]}>
                <Input />
              </Form.Item>
            </div>
            <div className='form__col'>
              <Form.Item
                label={<p style={{ fontWeight: 'normal', marginBottom: '-2px' }}>Code</p>}
                name='code'
                rules={[
                  {
                    required: false,
                    message: 'Please input Code!'
                  }
                ]}>
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className='form__row--footer'>
            <Button type='primary' className="custom-btn--primary"
              htmlType='submit' >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddCustomerForm;
