import React, { useState, useEffect } from "react";
import "./productStyle.scss";
import { Table, Typography } from "antd";
import { useHistory } from "react-router-dom";
import ProductsNestedTable from "./productsViewNestedTable";
import {
  getDataFromLocalStorage,
  checkUserAuthFromLocalStorage,
} from "../../../../utils/local-storage/local-store-utils";
import Constants from "../../../../utils/constants/constants";
import * as Helpers from "../../../../utils/helpers/scripts";
import { BarcodeOutlined } from "@ant-design/icons";
import PrintBarcode from "./printBarcode";

const ProductsTable = (props) => {
  const { selectedOutletData = null } = props;
  const [data, setData] = useState([]);
  const [currentPageNumber, setcurrentPageNumber] = useState(1);
  const [tableExpandedRows, setTableExpandedRows] = useState([]);
  const [recordSelected, setRecordSelected] = useState(null);
  const history = useHistory();

  const handleDelete = (record) => {
    if (record.variant > 1) {
      return toggleExpandByproductId(record.product_id);
    }
    history.push({
      pathname: `/products/${record.product_id}/delete`,
      data: record, // your data array of objects
    });
  };

  const edit = (record) => {
    if (record.variant > 1) {
      return toggleExpandByproductId(record.product_id);
    }

    history.push({
      pathname: `/products/${record.product_id}/edit`,
      data: record, // your data array of objects
    });
  };

  const showTotalItemsBar = (total, range) => {
    //console.log(range);
    return `${range[0]}-${range[1]} of ${total} items`;
  };

  const handlePageChange = (page, pageSize) => {
    setcurrentPageNumber(page);
    props.onClickPageChanger(page);
  };

  const handleSetUserCount = (record) => {
    let count = window.prompt(
      `No. of barcodes for SKU: ${record.product_sku}`,
      0
    );

    var storeObj = null;
    var symbol = null;
    var readFromLocalStorage = getDataFromLocalStorage(
      Constants.USER_DETAILS_KEY
    );
    readFromLocalStorage = readFromLocalStorage.data
      ? readFromLocalStorage.data
      : null;
      console.log("readFromLocalStorage", readFromLocalStorage)
    if (readFromLocalStorage) {
      //symbol = readFromLocalStorage.currency.symbol;     //imp prev
      symbol = selectedOutletData.currency_symbol; //imp new one
      symbol = symbol || ""; //imp new one
      if (
        checkUserAuthFromLocalStorage(Constants.USER_DETAILS_KEY).authentication
      ) {
        var foundStoreObj = readFromLocalStorage.auth.storeInfo.find((obj) => {
          return obj.store_id === readFromLocalStorage.auth.current_store;
        });
        if (foundStoreObj) {
          storeObj = foundStoreObj;
        }
      } else {
        storeObj = null;
      }
    }
    if (count > 0) {
      const barcodeData = {
        ...record,
        symbol: symbol,
        storeObj: storeObj,
        count: count,
      }
      setRecordSelected(barcodeData);
      return barcodeData;
    }
  };

  const handlePrintBarcode = (countRes) => {
    if (countRes.count > 0) {
      let previewBarcodeHtml = document.getElementById("printBarcode");
      if (!previewBarcodeHtml) {
        return;
      }
      previewBarcodeHtml = document.getElementById("printBarcode").innerHTML;
      let doc =
      `<html><head><title></title><link rel="stylesheet" type="text/css" href="/printBarcodeStyle.css" /><style type="text/css">
      .printBarcode-file {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        margin: 0px 20px;
      }
      .w-100 {
        width: 100%;
      }
      .topleft {
        display: inline-block; 
        font-size: 10px;    
      }
      .topright {
        display: inline-block;   
        float: right; 
        font-size: 10px;    
      } 
      .center {
        text-align: center;
        width: 100%;
        font-size: 10px;    
      }
      .text-elipse {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;    
        width: 50%;
      }
   </style>
      </head><body onload="window.print(); setTimeout(window.close, 0);" >` +
        previewBarcodeHtml +
        "</body></html>";
      /* NEW TAB OPEN PRINT */
      let popupWin = window.open(
        "",
        "_blank",
        "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=400,width=500,height=500"
      );
      if (popupWin) {
        popupWin.document.open();
        //window.print(); window.close(); //'width: 80%, height=80%'
        popupWin.document.write(doc);
        popupWin.document.close(closeFunction()); //vvimp for autoprint
      }
    }
  };
  const closeFunction = () => {
    setRecordSelected(null);
  }
  const barcodeGenerator = async (record) => {
    if (record.variant > 1) {
      return toggleExpandByproductId(record.product_id);
    }
    const countRes = await handleSetUserCount(record);
    if (countRes) {
      await handlePrintBarcode(countRes);
    }
  };

  const toggleVariants = (record) => {
    if (record.variant > 1) {
      toggleExpandByproductId(record.product_id);
    }
  };

  const tableExpandedRowRender = (record, index, indent, expanded) => {
    return <ProductsNestedTable productUniqId={record.product_unique} />;
  };

  const onRowExpand = (expanded, record) => {
    toggleExpandByproductId(record.product_id);
  };

  const toggleExpandByproductId = (productId) => {
    var expandedRows = tableExpandedRows;
    const index = expandedRows.indexOf(productId.toString());
    if (index > -1) {
      expandedRows.splice(index, 1);
    } else {
      expandedRows.push(productId.toString()); /*imp convert to string[]*/
      console.log(expandedRows);
    }

    setTableExpandedRows([...expandedRows]); //vvimp to re-render
  };

  useEffect(async () => {
    setData(props.tableData);
    if (
      props.paginationData &&
      currentPageNumber > Math.ceil(props.paginationData.totalPages)
    ) {
      setcurrentPageNumber(1);
    }
  }, [
    props.tableData,
    props.tableDataLoading,
    props.paginationData,
    props.selectedOutletData,
    tableExpandedRows,
  ]); /* imp passing props to re-render */

  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      //width: "20%",
      className: "product-name-heading",
      render: (_, record) => {
        return (
          <div>
            {record.product_name}
            <br />
            <small>{record.product_sku}</small>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category_name",
    },
    ,
    {
      title: "QTY",
      dataIndex: "product_quantity",
    },
    {
      title: "Variants",
      //dataIndex: "variant",
      render: (_, record) => {
        return (
          <div className="action-btns">
            <Typography.Link onClick={() => toggleVariants(record)}>
              {record.variant > 1 ? record.variant + " variants" : "-"}
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: (
        <div>
          Sale Price<small>(Exclusive of GST)</small>
        </div>
      ),
      //dataIndex: "product_sale_price",
      render: (_, record) => {
        let exclusiveSalePrice = record.prices.product_sale_price_excl;
        return <span>{parseFloat(exclusiveSalePrice).toFixed(2)}</span>;
      },
    },
    {
      title: (
        <div>
          Discount Price<small>(Exclusive of GST)</small>
        </div>
      ),
      //dataIndex: "product_sale_price",
      render: (_, record) => {
        let exclusiveDiscountPrice = record.prices.product_discount_price_excl;
        return <span>{parseFloat(exclusiveDiscountPrice).toFixed(2)}</span>;
      },
    },
    {
      title: "Vendor Code",
      //dataIndex: "product_vendor_code",
      render: (_, record) => {
        let vendorExists = Helpers.var_check_updated(record.product_vendor_code);
        return (
          <span>
            {vendorExists && record.product_vendor_code}
          </span>
        );
      },
    },
    {
      title: "Barcode",
      render: (_, record) => {
        return (
          <div className="action-btns">
            <BarcodeOutlined
              onClick={() => barcodeGenerator(record)}
            ></BarcodeOutlined>
          </div>
        );
      },
    },
    {
      title: "Operations",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <div className="action-btns">
            <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>
            {data.length >= 1 ? (
              <Typography.Link onClick={() => handleDelete(record)}>
                Delete
              </Typography.Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataindex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        className="table-frame products-listing-table"
        pagination={{
          total: props.paginationData && props.paginationData.totalElements,
          showTotal: (total, range) => showTotalItemsBar(total, range),
          defaultPageSize: 20,
          pageSize: parseInt(props.pageLimit),
          showSizeChanger: false,
          current: currentPageNumber,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          position: ["topRight"],
        }}
        //loading={props.tableDataLoading}
        rowKey="product_id"
        expandedRowKeys={tableExpandedRows}
        expandedRowRender={tableExpandedRowRender}
        onExpand={onRowExpand}
      />
      {recordSelected && <PrintBarcode recordSelected={recordSelected} />}
    </>
  );
};

export default ProductsTable;
